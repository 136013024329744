<template>
  <component :is="tag" class="accordion">
    <slot>
      <accordion-item
          @addedCard="addedCard"
          @removeCard="removeCard"
          v-for="(item, index) in items"
          :index="index"
          :id="item['id']"
          :title="item[titleName]"
          :term="item['term']"
          :cards="item['cards']"
          :desc="item['desc']"
          :key="index"
          :currentOpen="currentOpen"
      ></accordion-item>
    </slot>
  </component>
</template>

<script>
import {reactive, computed, provide, ref} from 'vue';
import AccordionItem from "./AccordionItem";

export default {
  name: 'Accordion',
  components: {AccordionItem},
  props: {
    tag: {
      type: String,
      default: 'div'
    },

    items: {
      default: {}
    },

    titleName: {
      default: 'title'
    },
    singleOpen: {
      type: Boolean,
      default: false
    },
    desc: {
      type: String,
      default: "",
    }
  },

  setup(props, {emit}) {
    // create an empty map for storing accordion items
    const items = reactive(new Map());
    const currentOpen = ref(0);

    // computed prop for managing state
    const state = computed({
      get() {
        // map the items
        return Array.from(items).map(item => ({
          id: item[0], // i.e. key
          open: item[1].open, // i.e. value
        }));
      },
      set(currentStates) {
        // for each item, make sure its current 'open' state matches
        currentStates.forEach(currentState => {
          const item = items.get(currentState.id);
          if (item?.open !== currentState.open) {
            item.toggle();
          }
        });
      },
    });

    const emitChange = (itemId, open) => {
      emit('change', {
        change: {id: itemId, open},
        state: state.value,
      });
    };

    const addedCard = (value) => {
      emit('addedCard', value);
    }

    const removeCard = (value) => {
      emit('removeCard', value);
    }

    // provide needed functions to accordion items
    provide('addItem', (itemId, item) => {
      items.set(itemId, item);
    });
    provide('removeItem', itemId => {
      items.delete(itemId);
    });

    provide('buttonClicked', (clickedItemId, open, clicked, changedOpen) => {
      const arr = Array.from(items)
      if (clicked === 'next') {
        const nextId = arr[currentOpen.value + 1][0];
        currentOpen.value = currentOpen.value + 1;
        items.get(nextId).open = open;
        emitChange(nextId, open);
        items.forEach((item, itemId) => {
          if (itemId === nextId && !item.open) {
            item.toggle()
            emitChange(nextId, true);
          }
        })
      } else if (clicked === 'prev') {
        const nextId = arr[currentOpen.value - 1][0];
        currentOpen.value = currentOpen.value + 1;
        items.get(nextId).open = open;
        emitChange(nextId, open);
        items.forEach((item, itemId) => {
          if (itemId === nextId && !item.open) {
            item.toggle()
            emitChange(nextId, true);
          }
        })
      } else {
        const currentId = arr[changedOpen][0];
        currentOpen.value = changedOpen;
        items.get(currentId).open = open;
        emitChange(currentId, open);
      }


      items.get(clickedItemId).open = open;
      emitChange(clickedItemId, open);
      items.forEach((item, itemId) => {
        if (itemId === clickedItemId && !item.open) {
          item.toggleClose(true);
          emitChange(itemId, false);
        }
      })
    })

    provide('onItemChange', (clickedItemId, open) => {
      items.get(clickedItemId).open = open;

      emitChange(clickedItemId, open);

      // check if only allow single item open
      if (props.singleOpen) {
        items.forEach((item, itemId) => {
          // if not the clicked item and if open, close the item
          if (itemId !== clickedItemId && item.open) {
            item.toggleClose(true);
            emitChange(itemId, false);
          }
        });
      }
    });

    emit('accordionEmit', {items, emitChange,state})

    return {
      state,
      currentOpen,
      addedCard,
      removeCard,
    };
  },
}
</script>
