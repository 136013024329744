<template>
  <div class="selection-guide-container">
    <div v-if="displayTitle()" class="section-title">
      <h2 class="h1">{{ this.title.titleLine1 }}</h2>
      <p>{{ this.title.desc }}</p>
    </div>
    <div class="selection-header">
      <div class="selection-carousel">
        <pa-product-carousel
            @selectedCard="showCardContent"
            :cards="getEpoxies"
            :gap="40"
            :type="'slider'"
            :bound="true"
            :count="getEpoxies.length"
            :startAt="0"
            :breakpoints="{
              568: {perView: 1},
              1024: {perView: 2},
              1300: {perView: 3},
              4000: {perView: 5}
              }">
        </pa-product-carousel>
      </div>
    </div>
    <div class="selection-content-wrapper container">
      <SelectionContent
          v-for="content in getEpoxies"
          :content="content"
          :selectedCard="selectedCard"
          :key="getID()"
      />
    </div>
    <SelectionGuide
        @accordionEmit="accordionEmit"
        @review="reviewCards"
        v-if="show.selectionGuide"
        :posts="filteredPosts"
        :taxonomies="taxonomyFilters"
    />
    <Review
        :where="where"
        v-if="show.review"
        :emitter="accordionEmitter"
        :cards="selectionCards"
        :taxonomies="taxonomyFilters"
        :content="reviewer"
        :is-105-system="selectedCard === '105-system'"
    />
  </div>
</template>

<script>
import Mixins from "./mixins/Mixins";
import SelectionContent from "./content/SelectionContent";
import SelectionGuide from "./selection-guide/SelectionGuide";
import Review from "./review/Review";

export default {
  components: {Review, SelectionGuide, SelectionContent},
  mixins: [Mixins],
  props: {
    posts: {
      type: Array,
    },
    taxonomies: {
      type: Array,
    },
    reviewer: {
      type: Object,
    },
    where: {
      type: Object,
    },
    title: {
      type: Object,
    }
  },

  data() {
    return {
      accordionEmitter: null,
      termFilter: null,
      selectedCard: "",
      term: 'epoxy',
      selectionCards: [],
      show: {
        selectionGuide: false,
        review: false,
      },
    };
  },
  computed: {
    filteredPosts() {
      if (this.termFilter === null) {
        return this.posts;
      }
      return this.posts.filter((post) => {
        return Mixins.methods.postHasTerm(post, this.termFilter.term_id);
      });
    },

    getEpoxies() {
      if (this.term === null) {
        return this.posts;
      }
      return Mixins.methods.getProductFromCat(this.posts, this.term);
    },

    taxonomyFilters() {
      let filters = {};
      for (let term of this.taxonomies) {
        filters['terms'] = term;
      }
      return filters;
    }
  },
  methods: {
    showCardContent(value) {
      Mixins.methods.scrollIntoView();
      this.selectedCard = value;

      // Selection Guide will show only for '105-system`
      if (this.selectedCard == '105-system') {
        this.show = {
          selectionGuide: true,
          review: true,
        };
      } else {
        this.show = {
          selectionGuide: false,
          review: true,
        };
        // Reset selection cards
        this.selectionCards = [];
      }
    },
    getID() {
      return Mixins.methods.generateUUID()
    },
    reviewCards(value) {
      this.selectionCards = value;
    },
    displayTitle() {
      return this.title.titleLine1 && this.title.desc;
    },
    accordionEmit(value) {
      this.accordionEmitter = value;
    },
  }
}
</script>