import { createApp } from "vue";
import config from './../config.json';
import components from '@/components/global';

const main = createApp({
    mounted() {
        console.log("PA Base JS enqueued & Vue instance mounted!");
    }
});

for (const [name, component] of Object.entries(components)) {
    main.component(name, component);
}

let vue_prefix = config.plugin_prefix.replaceAll('_', '-');

let element = document.querySelector(`.${vue_prefix}-vue-instance`);

main.mount(element);