<template>
    <component :is="getTag()" class="card__title" v-html="getCardTitle()"></component>
</template>

<script>
export default {
    data: function () {
        return {
            defaultTag: 'h4'
        }
    },
    props: {
        titleTag: {
            type: String,
            default: ''
        },
        titleLine1: {
            type: String,
            default: ''
        },
        titleLine2: {
            type: String,
            default: ''
        }
    },
    mounted() {
        // console.log("Card title component mounted!");
    },
    methods: {
        getTag: function () {
            return this.titleTag ? this.titleTag : this.defaultTag;
        },
        getCardTitle: function () {
            let cardTitle = '';
            if (this.titleLine1 && this.titleLine2) {
                cardTitle = this.titleLine1;
            }
            cardTitle += (this.titleLine1 && this.titleLine2) ? '<span><small>' + this.titleLine2 + '</small></span>' : (this.titleLine1 ? this.titleLine1 : this.titleLine2);
            return cardTitle;
        },
    }
}
</script>
