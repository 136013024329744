<template>
  <div v-show="checkSelected()" class="selection-content">
    <div class="content" v-html="this.content.meta.pa_products_product_content"></div>
  </div>
</template>

<script>

export default {
  props: {
    content: {
      type: Object,
      default: {}
    },
    selectedCard: {
      type: String,
      default: ""
    }
  },
  methods: {
    checkSelected: function () {
      return this.content.post_name === this.selectedCard && this.checkContentMeta();
    },
    checkContentMeta: function () {
      const meta = this.content.meta.pa_products_product_content;
      if (meta) {
        return meta.length > 0 ? true : false;
      }
    }
  }
}
</script>