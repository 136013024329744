<template>
    <div class="glide__bullets" data-glide-el="controls[nav]">
        <button class="glide__bullet" v-for="n in count" :data-glide-dir="'='+(n - 1)"></button>
    </div>
</template>

<script>
export default {
    props: {
        count: {
            type: Number
        }
    },
}
</script>
