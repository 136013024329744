<template>
  <div class="selection-guide">
    <pa-accordion
        @accordionEmit="accordionEmit"
        @addedCard="addedCard"
        @removeCard="removeCard"
        :items="getSelectionContent"
        :singleOpen="true">
    </pa-accordion>
  </div>
</template>

<script>
import Mixins from "../mixins/Mixins";

export default {
  mixins: [Mixins],
  props: {
    posts: {
      type: Array,
    },
    taxonomies: {
      type: Object,
    },
  },

  data() {
    return {
      selectedItems: [],
      sortedSelection: [],
    }
  },
  computed: {
    getSelectionContent() {
      const selectionContent = []
      if (this.taxonomies && this.posts) {
        if (this.taxonomies.terms && this.taxonomies.terms.length > 0) {
          this.taxonomies.terms.forEach((term) => {
            if (term.slug == 'hardener'
                || term.slug == 'additives'
                || term.slug == 'filler') {
              const order = Mixins.methods.checkForCatTerm(term, 'pa_products_cat_order');
              const cards = Mixins.methods.getProductFromCat(this.posts, term.slug);

              const obj = {
                'id': Mixins.methods.generateUUID(),
                'term': `${term.slug}`,
                'title': `${order.length ? order[0].pa_products_cat_order + '.' : ""} select your ${term.name} ${term.slug == 'additives' ? '(Optional)' : ""}`,
                'cards': cards,
              }
              if (this.checkForTermDesc(term)) {
                obj.desc = term.description;
              }
              if (order.length) {
                obj.order = parseInt(order[0].pa_products_cat_order[0]);
              }
              selectionContent.push(obj)
            }
          })
        }
      }
      const sortedSelection = selectionContent.sort(({order: a}, {order: b}) => a - b);
      this.sortedSelection = sortedSelection; // copy so can be reused
      return sortedSelection;
    }
  },
  methods: {
    checkForTermDesc(term) {
      return term.description !== '' ? true : false;
    },
    addedCard(value) {
      if (this.selectedItems.length === 0) {
        this.selectedItems.push(value);
      } else {
        const index = this.sortedSelection.findIndex(card => value.term.includes(card.term));
        if (index === -1) {
          this.selectedItems.push(value);
        } else {
          this.selectedItems[index] = value;
        }
      }
      this.$emit('review', this.selectedItems);
    },
    removeCard(value) {
      const index = this.sortedSelection.findIndex(card => value.term.includes(card.term));
      if (index !== -1) {
        this.selectedItems.splice(index, 1);
      }
      this.$emit('review', this.selectedItems);
    },
    accordionEmit(value){
      this.$emit('accordionEmit', value);
    }
  }
}
</script>