import generateId from "../../composables/use-identifier";

export default {
    methods: {
        generateUUID() {
            const identifier = generateId();
            return identifier.value;
        },
        getProductFromCat(posts, type) {
            const arr = [];
            if (posts && type) {
                posts.filter((post) => this.checkForTerms(post)
                    && post.terms.filter((term) => term.slug === type ? arr.push(post) : false)
                )
            }
            return arr;
        },
        checkForTerms(post) {
            return (post.hasOwnProperty('terms') && Array.isArray(post.terms));
        },
        checkForCatTerm(term, metaKey) {
            if (term.hasOwnProperty('term_meta') && Array.isArray(term.term_meta)) {
                const termMeta = term.term_meta.filter((meta) => meta.hasOwnProperty(metaKey) ? meta : []);
                return termMeta;
            }
            ;
        },
        getTerms(post) {
            const hasTerms = this.checkForTerms(post);
            if (hasTerms) {
                return post.terms.map((term) => term);
            }
        },
        scrollIntoView() {
            document.querySelector('.selection-content-wrapper').scrollIntoView({behavior: "smooth"})
        },
        postHasTerm(post, termId) {
            if (post.hasOwnProperty('terms') && Array.isArray(post.terms)) {
                return post.terms.map(term => term.term_id).includes(termId);
            }
            return false;
        },
    }
}