<template>
  <div class="carousel-wrapper" :class="externalArrows ? 'external-arrows' : ''">
    <div :id="carouselId" class="glide carousel">
      <slot name="track">
        <div class="glide__track carousel__track" data-glide-el="track">
          <ul class="glide__slides carousel__slides">
            <slot></slot>
            <card @activeCard="setSelectedCard" v-if="selectedCards" v-for="card in selectedCards" :card="card"></card>
          </ul>
        </div>
      </slot>

      <slot name="nav">
        <component v-if="navigation" class="carousel__nav" :is="navComponent"
                   :count="slideCount || this.count"></component>
      </slot>
    </div>
  </div>
</template>

<script>
import {ref, reactive, computed, h} from 'vue';
import CarouselNavArrows from "./navigation/CarouselNavArrows";
import CarouselNavButtons from "./navigation/CarouselNavButtons";
import CarouselNavBullets from "./navigation/CarouselNavBullets";
import Glide from '@glidejs/glide';
import generateId from "../../composables/use-identifier";
import Card from "../cards/Card";

// define the available navigation components
const navigationComponents = {
  arrows: CarouselNavArrows,
  bullets: CarouselNavBullets,
  buttons: CarouselNavButtons,
};

const optionKeys = [
  'type',
  'startAt',
  'perView',
  'focusAt',
  'gap',
  'autoplay',
  'hoverpause',
  'keyboard',
  'bound',
  'swipeThreshold',
  'dragThreshold',
  'perTouch',
  'animationDuration',
  'rewind',
  'rewindDuration',
  'animationTimingFunc',
  'direction',
  'peek',
  'breakpoints',
  'throttle'
];

export default {
  components: {Card},
  props: {
    // to override/specify which element
    //  the carousel should be mounted on
    mountOn: {
      type: String,
      default: null
    },
    // the type of HTML tag to use for each slide
    slideTag: {
      type: String,
      default: 'li'
    },
    // the carousel navigation type -- AKA "controls"
    //  e.g. arrows, bullets, buttons, etc.
    navigation: {
      type: String,
      default: 'arrows'
    },
    images: {
      type: Array
    },
    cards: {
      type: Array
    },
    count: {
      type: Number,
      default: 0
    },
    // can be used to override the default options via an object
    options: {
      type: Object,
      default: {}
    },
    type: {
      String,
      default: 'carousel'
    },
    /**
     * Start at specific slide number defined with zero-based index.
     */
    startAt: {
      type: Number,
      default: 0
    },
    /**
     * A number of slides visible on the single viewport.
     */
    perView: {
      type: Number,
      default: 1
    },
    /**
     * Focus currently active slide at a specified position in the track.
     *
     *      Available inputs:
     *      - 'center' - current slide will be always focused at the center of a track,
     *      - 1,2,3... - current slide will be focused on the specified zero-based index.
     */
    focusAt: {
      type: [String, Number],
      default: 0
    },
    /**
     * A size of the gap added between slides (in px)
     *
     *      e.g. 10
     */
    gap: {
      type: Number,
      default: 0
    },
    /**
     * Change slides after a specified interval. Use false for turning off autoplay.
     */
    autoplay: {
      type: [Boolean, Number],
      default: false
    },
    /**
     * Stop autoplay on mouseover event.
     */
    hoverpause: {
      type: Boolean,
      default: true
    },
    /**
     * Allow for changing slides with left and right keyboard arrows.
     */
    keyboard: {
      type: Boolean,
      default: true
    },
    /**
     * Stop running perView number of slides from the end.
     * Use this option if you don't want to have an empty space after a slider.
     * (Works only with slider type and a non-centered focusAt setting.)
     */
    bound: {
      type: Boolean,
      default: false
    },
    /**
     * Minimal swipe distance needed to change the slide. Use false for turning off a swiping.
     */
    swipeThreshold: {
      type: [Number, Boolean],
      default: 80
    },
    /**
     * Minimal mouse drag distance needed to change the slide. Use false for turning off a dragging.
     */
    dragThreshold: {
      type: [Number, Boolean],
      default: 120
    },
    /**
     * A maximum number of slides to which movement will be made on swiping or dragging. Use false for unlimited.
     */
    perTouch: {
      type: [Number, Boolean],
      default: false
    },
    /**
     * Duration of the animation in milliseconds.
     */
    animationDuration: {
      type: Number,
      default: 400
    },
    /**
     * Allows looping the slider type. Slider will rewind to the first/last slide when it's at the start/end.
     * (Works only with slider type.)
     */
    rewind: {
      type: Boolean,
      default: true
    },
    /**
     * Duration of the rewinding animation of the slider type in milliseconds.
     */
    rewindDuration: {
      type: Number,
      default: 800
    },
    /**
     * Easing function for the animation.
     */
    animationTimingFunc: {
      type: String,
      default: 'cubic-bezier(0.165, 0.840, 0.440, 1.000)',
    },
    /**
     * Moving direction mode. Available inputs: 'ltr' or 'rtl'
     */
    direction: {
      type: String,
      default: 'ltr'
    },
    /**
     * The distance value of the next and previous viewports which have to peek in the current view.
     * Accepts number and pixels as a string. Left and right peeking can be setup separately with a directions object.
     *
     *      For example:
     *      - 100 - peek 100px on the both sides,
     *      - { before: 100, after: 50 } - peek 100px on the left side and 50px on the right side.
     */
    peek: {
      type: [Number, Object],
      default: 0
    },
    /**
     * Collection of options applied at specified media breakpoints.
     *
     *     For example, display two slides per view under 800px:
     *      {
     *        800: {
     *          perView: 2
     *        }
     *      }
     */
    breakpoints: {
      type: Object,
      default: {}
    },
    /**
     * Throttle costly events at most once per every wait milliseconds.
     */
    throttle: {
      type: Number,
      default: 25
    },
    externalArrows: {
      type: Boolean,
      default: false
    },
  },

  setup(props, {slots, attr, emit}) {
    const identifier = generateId();
    const mountElement = ref(null);
    const glide = ref(null);
    const slides = ref([]);

    // if the cards prop is not set, then move on to setup slids using the default slot or images prop.
    if (!props.cards?.length) {
      // if slides are declared via the default slot, map those elements with the necessary markup
      if (slots.default()) {
        slides.value = slots.default().map((item) => {
          return h(props.slideTag, {
            ...item.props,
            class: item.props.hasOwnProperty('class') ? `${item.props.class} glide__slide` : 'glide__slide'
          }, item.children);
        });
      } // else if slides are declared via the images prop, map them to the slide elements
      else if (props.images?.length) {
        slides.value = props.images.map((image) => {
          return h(props.slideTag, {
            ...image.props,
            class: image.props.hasOwnProperty('class') ? `${image.props.class} glide__slide` : 'glide__slide'
          }, image.children);
        });
      }
    }

    // create a unique carousel ID to prevent conflicts
    const carouselId = computed(() => {
      return `carousel-${identifier.value}`;
    });

    // set the element for mounting the carousel
    mountElement.value = props.mountOn ? !props.mountOn : `#${carouselId.value}`;

    // get the default carousel options
    let withOptions = {
      ...props.options
    };

    // set the carousel options specified via props
    for (const optionName of optionKeys) {
      if (props.hasOwnProperty(optionName) && props[optionName] !== undefined) {
        withOptions[optionName] = props[optionName];
      }
    }

    // declare the carousel options to use
    const carouselOptions = reactive(withOptions);

    // create the Glide instance
    glide.value = new Glide(mountElement.value, carouselOptions);

    return {
      carouselId,
      carouselOptions,
      glide,
      slides
    };
  },

  data() {
    return {
      loaded: false,
      selectedCards: this.cards
    };
  },

  computed: {
    isDisabled() {
      return this.glide.disabled;
    },

    navComponent() {
      return !!this.navigation && navigationComponents.hasOwnProperty(this.navigation)
          ? navigationComponents[this.navigation]
          : navigationComponents.arrows;
    },

    slideCount() {
      return this.slides ? this.slides.length : this.count;
    }
  },

  mounted() {
    this.glide.mount();

    this.loaded = true;

    this.$emit('mounted:carousel', this);
  },

  unmounted() {
    this.glide.destroy();
  },

  methods: {
    updateOptions(options) {
      this.glide.update(options);
    },
    setSelectedCard(value) {
      this.selectedCards.filter((card) => card.post_name !== value ? card.activeCard = false : card.activeCard = true)
      this.$emit('selectedCard', value)
    }
  }
}
</script>
