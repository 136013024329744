<template>
  <div @click="triggerAccordion" :data-id="getAccordionId()"
       :class="`card--review review-${getCardTerm()} align-center`">
    <div v-if="displayCardHeader()" class="card__header">
      <h4 class="card__title">{{ card.post_title }}</h4>
    </div>
    <div v-if="displayImage()" class="block image" v-html="card.featured_image"></div>
  </div>
</template>

<script>
import CardTitle from "./CardTitle";

export default {
  components: {CardTitle},
  data: function () {
    return {
      defaultClass: 'align-center',
      initialCard: true,
    }
  },
  props: {
    card: {
      type: Object,
      default: {}
    },
    id: {
      type: String,
      default: ""
    },
    emitter: {
      type: Object,
      default: {}
    }
  },
  methods: {
    displayImage: function () {
      return this.card.featured_image;
    },
    displayCardHeader: function () {
      return this.card.post_title;
    },
    getCardTerm: function () {
      return this.card.term ? `${this.card.term} default--card initial--card ` : `${this.card.terms[0].slug} default--card`
    },
    getAccordionId: function () {
      return this.id;
    },
    isInitialCard: function () {
      if (this.card.initial === undefined) {
        this.initialCard = false;
        return false;
      }
      return true;
    },
    triggerAccordion: function (e) {
      if (this.isInitialCard()) {
        const id = e.target.dataset.id;
        this.emitter.items.forEach((item, itemId) => {
          if (itemId === id) {
            item.toggleOpen()
            this.emitter.emitChange(id, true);
          }
        })
      }
    }
  }
}
</script>
