<template>
  <div :class="`${term ? term + '__product-selection': ''}`">
    <component :is="tag"
               class="accordion__item"
               :class="{
                    'accordion__item--active': isOpen,
                    'accordion__item--disabled': disabled,
                }">

      <component :is="headingTag"
                 :type="headingTag === 'button' ? 'button' : undefined"
                 :aria-expanded="isOpen ? 'true' : 'false'"
                 :aria-controls="id"
                 class="accordion__heading"
                 @click.prevent.stop="onClick">

        <h3 class="accordion__title">
          <!-- @slot title for the accordion item -->
          <slot name="title">
            <span v-if="title" v-html="title"></span>
          </slot>
        </h3>

        <div v-if="!noIcon" class="accordion__icon">
          <!-- @slot icon for the accordion item -->
          <Icon :active="isOpen" :selected="selectedCard"/>
        </div>
      </component>

      <slide-vertical
          :active="isOpen"
          :id="id"
          :cardClicked="cardClicked"
          :unSelected="cardUnselected">
        <div class="accordion__body">
          <div v-if="desc" class="accordion__body-description">
            <p class="accordion__description" v-html="desc"></p>
          </div>

          <slot>
            <div v-if="selectedCards" class="accordion__body-cards container">
              <pa-product-cards
                  @removeCard="removeActiveCards"
                  @addedCard="setActiveCards"
                  :selection="true"
                  :class="'grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3'"
                  :cards="selectedCards">
              </pa-product-cards>
            </div>
          </slot>
          <div class="selection-content-wrapper container">
            <SelectionContent v-for="content in cards" :content="content" :selectedCard="this.selectedCard"
                              :key="getID()"/>
          </div>
          <div class="selection-footer container">
            <div class="selection-nav">
              <button @click="onPrev" class="button hollow prev-btn" v-if="index !== 0">LAST STEP</button>
              <button @click="onNext" class="button hollow" v-if="index !== 2">NEXT STEP</button>
            </div>
          </div>
        </div>
      </slide-vertical>
    </component>
  </div>
</template>

<script>
import {inject, ref, onMounted, onBeforeUnmount, reactive} from 'vue';
import SlideVertical from "../transitions/SlideVertical";
import SelectionContent from "../content/SelectionContent";
import Icon from "../icon/icon";
import Mixins from "../mixins/Mixins";
import useIdentifier from "../../composables/use-identifier";

export default {
  name: 'AccordionItem',
  mixins: [Mixins],
  components: {SlideVertical, SelectionContent, Icon},
  props: {
    id: {
      type: [String, Number]
    },

    open: {
      type: Boolean,
      default: false
    },

    disabled: {
      type: Boolean,
      default: false
    },

    title: {
      type: String
    },
    term: {
      type: String,
    },
    tag: {
      type: String,
      default: 'div'
    },

    headingTag: {
      type: String,
      default: 'div'
    },

    cards: {
      type: Array,
      default: []
    },

    desc: {
      type: String,
      default: ""
    },

    noIcon: {
      type: Boolean,
      default: false
    },
    index: {
      type: Number,
      default: 0,
    },
    currentOpen: {
      type: Number,
      default: 0,
    },
  },

  setup(props, {emit}) {
    // inject functions from accordion
    let selectedCard = ref('');
    let cardClicked = ref(false);
    let cardUnselected = ref(false);
    let selectedCards = ref(props.cards);
    const addItem = inject('addItem');
    const removeItem = inject('removeItem');
    const onItemChange = inject('onItemChange');
    const onButtonClicked = inject('buttonClicked');
    const currentOpen = ref(props.currentOpen);
    const changedOpen = ref(props.index);
    const setIcon = ref(false);

    // add a unique identifier
    const id = useIdentifier();
    const isOpen = ref(props.open);

    const scrollSelectionIntoView = () => {
      const parent = document.querySelector('.selection-guide ')
      parent.scrollIntoView({behavior: "smooth", block: 'start', inline: "start"})
    }

    // method for toggling accordion item
    const toggle = (forceState) => {
      const newState = forceState !== undefined ? forceState : !isOpen.value;

      // set the new open state
      isOpen.value = newState;

      // emit event for the item change
      onButtonClicked(id.value, newState, '', changedOpen.value);
      onItemChange(id.value, newState);
    };

    // method for opening accordion item
    const toggleOpen = (silent = false) => {
      // set the new open state
      isOpen.value = true;
      scrollSelectionIntoView()

      // if not silent, emit event for the item change
      if (!silent) {
        onItemChange(id.value, true);
      }
    };

    const checkActiveCards = () => {
      return props.cards.some((card) => card.activeCard === true);
    }



    const onPrev = () => {
      onButtonClicked(id.value, false, 'prev');
      scrollSelectionIntoView();
    }

    const onNext = () => {
      scrollSelectionIntoView()
      onButtonClicked(id.value, false, 'next');
    }

    // method for closing accordion item
    const toggleClose = (silent = false) => {
      // set the new closed state
      isOpen.value = false;
      scrollSelectionIntoView()

      // if not silent, emit event for the item change
      if (!silent) {
        onButtonClicked(id.value, false);
        onItemChange(id.value, false);
      }
    };

    const setCurrentOpen = () => {
      isOpen.value ? currentOpen.value = props.index : 0
    }

    const setActiveCards = (value) => {
      cardClicked.value = true;
      selectedCard.value = value.data.post_name;
      cardUnselected.value = false;
      emit('addedCard', value);
    }

    const removeActiveCards = (value) => {
      cardClicked.value = false;
      selectedCard.value = ""
      cardUnselected.value = true;
      emit('removeCard', value);
    }

    // toggle the accordion item on click
    const onClick = () => {
      toggle(!isOpen.value);
      setCurrentOpen()
    };

    // on mount, register the accordion item
    onMounted(() => {
      addItem(id.value, {open: isOpen.value, toggle, toggleOpen, toggleClose});
    });

    // remove the item before unmount
    onBeforeUnmount(() => {
      removeItem(id.value);
    });

    // pass the data/methods to the instance
    return {
      id,
      isOpen,
      onClick,
      selectedCard,
      selectedCards,
      cardClicked,
      currentOpen,
      onPrev,
      onNext,
      setIcon,
      removeActiveCards,
      setActiveCards,
      cardUnselected,
    };
  },
  methods: {
    getID() {
      return Mixins.methods.generateUUID()
    },
    setIcon() {
      if (this.cardClicked && !this.isOpen) {
        this.setIcon.value = true;
      }
    },
  }
};
</script>
