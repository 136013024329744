<template>
  <div @click="setActiveOrNot" class="card align-center"
       :class="`${checkIf105System() ? 'standard-card' : 'selection-card'} ${isActive() ? 'active--card' : ''} `">
    <div v-if="displayCardHeader()" class="card__header">
      <h4 class="card__title">{{ card.post_title }}</h4>
    </div>
    <div v-if="displayImage()" class="block image" v-html="card.featured_image"></div>
    <div v-if="displayCardFooter()" :class="`card__footer ${selection ? 'selection__footer': ''}`">
      <button @click="setActive" :class="`button hollow no-icon`" type="button"
              v-html="cardCtaText()"></button>
      <button @click="removeActive" v-if="isActive() && selection" type="button"
              class="button hollow no-icon unselect">
        UNSELECT
      </button>
    </div>
  </div>
</template>

<script>
import CardTitle from "./CardTitle";
import Mixins from "../mixins/Mixins";

export default {
  components: {CardTitle},
  mixins: [Mixins],
  data: function () {
    return {
      defaultClass: 'align-center',
      ctaText: 'Select',
    }
  },
  props: {
    card: {
      type: Object,
      default: false
    },
    selection: {
      type: Boolean,
      default: false,
    }
  },
  mounted() {
    // console.log("Card component mounted!");
  },
  methods: {
    displayImage: function () {
      return this.card.featured_image;
    },
    displayCardHeader: function () {
      return this.card.post_title;
    },
    displayCardFooter: function () {
      return this.card.meta.pa_products_product_content && this.card.meta.pa_products_product_content.length > 0;
    },
    checkIf105System: function () {
      return this.card.post_title === "105 System"
    },
    getPostName: function () {
      return this.card.post_name
    },
    productType: function () {
      const terms = Mixins.methods.getTerms(this.card);
      if (terms) {
        return terms.map(v => v.slug);
      } else {
        return "noTerm"
      }
    },
    setActive: function () {
      if (this.selection) {
        const term = this.productType()
        this.$emit('activeSelectionCard', {data: this.card, term})
      } else {
        this.$emit('activeCard', this.getPostName())
      }
    },
    isActive: function () {
      return this.card.activeCard;
    },
    removeActive: function () {
      const term = this.productType()
      this.$emit('removeActiveCard', {data: this.card, term});
    },
    cardCtaText: function () {
      if (this.checkIf105System() && this.isActive()) {
        this.ctaText = 'Selected';
      } else if (this.checkIf105System() && !this.isActive()) {
        this.ctaText = 'Get Started'
      } else if (this.selection) {
        if (this.isActive()) {
          this.ctaText = 'Selected'
        } else {
          this.ctaText = 'Select'
        }
      } else {
        if (this.isActive()) {
          this.ctaText = 'Selected'
        } else {
          this.ctaText = 'Select'
        }
      }
      return this.ctaText;
    },
    setActiveOrNot(e) {
      if (e.target.classList.contains('unselect')) {
        this.removeActive();
      } else {
        this.setActive();
      }
    }

  }
}
</script>
