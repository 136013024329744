<template>
  <div :class="classNames">
    <slot></slot>
    <card @activeSelectionCard="setSelectedCard" @removeActiveCard="removeSelectedCard" v-for="card in selectedCards"
          :card="card" :selection="true"></card>
  </div>
</template>

<script>
import Card from "./Card";

export default {
  components: {Card},
  data() {
    return {
      selectedCards: this.cards,
    }
  },
  props: {
    cards: {
      type: Array,
      default: [],
    },
    classNames: {
      type: String,
      default: "grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3",
    },
    buttonType: {
      type: String,
      default: ''
    }
  },
  methods: {
    setSelectedCard(value) {
      const cardClicked = value.data;
      this.selectedCards.filter((card) => card.post_name !== cardClicked.post_name ? card.activeCard = false : card.activeCard = true);
      this.$emit('addedCard', value);
    },
    removeSelectedCard(value) {
      const cardClicked = value.data;
      this.selectedCards.filter((card) => card.post_name === cardClicked.post_name ? card.activeCard = false : card.activeCard = false);
      this.$emit('removeCard', value)
    }
  }
}
</script>
