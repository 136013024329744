<template>
  <slot name="icon">
    <!-- default icon -->
    <svg v-if="selected == ''" :class="active ? 'open--arrow' : 'close--arrow' " width="32" height="17" viewBox="0 0 32 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M15.9997 0L17.3512 1.18452L32 13.8194L29.2969 17L16 5.52754L2.70306 17L0 13.8194L14.6488 1.18452L15.9997 0Z" fill="#131516"/>
    </svg>

    <svg v-if="selected !== ''" width="32" height="25" viewBox="0 0 32 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 13.7688L3.55488 9.05836L12.8 17.0298L27.7338 0L32 3.9859L13.1549 25L0 13.7688Z" fill="#66C67B"/>
    </svg>
  </slot>
</template>

<script>


export default {
  props: {
    active: {
      type: Boolean,
      default: false,
    },
    selected: {
      type: String,
      default: ""
    }
  },
  mounted() {
    // console.log("Card component mounted!");
  },

}
</script>
