import { computed } from 'vue';

/**
 * Generates a unique identifier (UUID)
 *
 * @returns {*}
 */
export const generateId = () => {
    const randomValues = c =>
        typeof crypto !== 'undefined'
            ? crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4))
            : (Math.random() * 16) >> (c / 4);

    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
        (c ^ randomValues(c)).toString(16)
    );
};

export const props = {
    id: String,
};

/**
 * Either returns the id that's passed as a prop or generates a new id
 *
 * @param props
 * @returns {ComputedRef<string>}
 */
const useIdentifier = props => {
    return computed(() => {
        return props?.id && props.id.length ? props.id : generateId();
    });
};

export default useIdentifier;
