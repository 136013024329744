<template>
  <div class="section-review container align-center"
    :style="{ 'margin-top': !is105System ? 0 : '80px' }">
    <div v-if="is105System">
      <div v-if="displayReviewHeader()" class="section-header">
        <h2 v-html="content.section_title"></h2>
        <p v-if="displayReviewDesc()" v-html="content.section_content"></p>
      </div>
      <div v-if="cards" class="section-content">
        <Cards>
          <ReviewCard v-for="(card,index) in updateCards" :card="card" :id="cardIds[index]" :emitter="emitter"/>
        </Cards>
      </div>
    </div>
    <div class="section-footer">
      <div class="actions">
        <a target="_blank" class="button" v-if="where" :href="where.ctaUrl" v-html="where.ctaText"></a>
        <button v-if="checkForModal()" :disabled="checkSelectedOptions" type="button" class="button secondary"
                @click="showModal">EMAIL ME MY
          SELECTIONS
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import ReviewCard from "../cards/ReviewCard";
import Cards from "../cards/Cards";

export default {
  components: {ReviewCard, Cards},
  data() {
    return {
      modalOpen: false,
      cardIds: [],
    }
  },
  props: {
    emitter: {
      type: Object,
      default: {}
    },
    cards: {
      type: Array,
      default: []
    },
    taxonomies: {
      type: Object,
      default: {}
    },
    content: {
      type: Object,
      default: {}
    },
    where: {
      type: Object,
      default: {}
    },
    is105System: {
      type: Boolean,
      default: false,
    }
  },
  computed: {
    updateCards() {
      const cards = [];
      const cardIds = [];
      const parse = JSON.parse(JSON.stringify(this.emitter))

      if (parse) {
        // Setup Initial Cards
        if (this.taxonomies && parse.state.length) {
          let count = 0;
          this.taxonomies.terms.forEach((term) => {
            if (term.slug == 'hardener'
                || term.slug == 'additives'
                || term.slug == 'filler') {
              const obj = {
                'term': `${term.slug}`,
                'post_title': `select your ${term.name} ${term.slug == 'additives' ? '(Optional)' : ""}`,
                'initial': true,
              }
              cards.push(obj)
              cardIds.push(parse.state[count].id)
              count++;
            }
          })
        }
      }

      const sortedSelection = cards.reverse()

      if (this.cards) {
        this.cards.forEach((card) => {
          const index = cards.findIndex(item => card.term.includes(item.term));
          if (index !== -1) {
            cards[index] = card.data;
          }
        })
      }
      this.cardIds = cardIds;
      return sortedSelection;
    },
    checkSelectedOptions() {
      const hardener = this.cards.some((card) => card.term === 'hardener');
      const filler = this.cards.some((card) => card.term === 'filler');
      if (hardener && filler) {
        return false;
      }
      return true;
    },
  },
  methods: {
    displayReviewHeader() {
      return this.content.section_title;
    },
    displayReviewDesc() {
      return this.content.section_content;
    },
    checkForModal() {
      const modal = document.querySelector('#reviewFormModal');
      return modal ? true : false;
    },
    addFormDataset() {
      const form = document.querySelector('#reviewFormModal .form');
      if (form) {
        const formData = [];
        this.cards.forEach((card) => {
          if (card.data) {
            const obj = {
              id: card.data.ID,
              term: card.term,
              url: card.data.url,
              featured_image: card.data.featured_image,
              title: card.data.post_title,
            }
            formData.push(obj);
          }
        })
        form.dataset.products = JSON.stringify(formData);
      }
    },
    populateForm() {
      const hardenerInput = document.querySelector('.hardener_epoxy_input input');
      const fillerInput = document.querySelector('.filler_epoxy_input input');
      const additivesInput = document.querySelector('.additives_epoxy_input input');
      const hardenerLinkInput = document.querySelector('.hardener_epoxy_link input');
      const fillerLinkInput = document.querySelector('.filler_epoxy_link input');
      const additivesLinkInput = document.querySelector('.additives_epoxy_link input');
      if (hardenerInput) {
        const hardener = this.cards.filter((card) => card.term === 'hardener');
        hardenerInput.value = hardener[0].data.post_title;
        if (hardenerLinkInput) {
          hardenerLinkInput.value = hardener[0].data.url;
        }
      }
      if (fillerInput) {
        const filler = this.cards.filter((card) => card.term === 'filler');
        fillerInput.value = filler[0].data.post_title;
        if (fillerLinkInput) {
          fillerLinkInput.value = filler[0].data.url;
        }
      }
      if (additivesInput) {
        const additives = this.cards.filter((card) => card.term === 'additives');
        if (additives.length) {
          additivesInput.value = additives[0].data.post_title;
          if (additivesLinkInput) {
            additivesLinkInput.value = additives[0].data.url;
          }
        }
      }
    },
    showModal() {
      this.modalOpen = !this.modalOpen;
      window.scrollTo(0, 0);
      const modal = document.querySelector('#reviewFormModal');
      this.addFormDataset();
      this.populateForm();
      modal.classList.toggle('active');
    }
  }
}
</script>
